<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.belirumaUser.noteList')"
    class="px-5 py-3"
  >
    <div style="position: relative">
      <v-btn
        type="button"
        color="success"
        @click="addNote"
        v-if="permissions.includes('ADD')"
        style="position: absolute; right: 0; top: -56px"
      >
        {{ $t('belirumaUser.addNote') }}
      </v-btn>
    </div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.note') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.noteTag') }}
          </th>
          <th class="primary--text">
            {{ $t('belirumaUser.createdBy') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            {{ $t('belirumaUser.updatedBy') }}-->
          <!--          </th>-->
          <th class="primary--text">
            {{ $t('belirumaUser.createdAt') }}
          </th>
          <!--          <th class="primary&#45;&#45;text">-->
          <!--            {{ $t('belirumaUser.updatedAt') }}-->
          <!--          </th>-->
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`note-${index}`">
          <td>{{ index + 1 }}</td>
          <td>
            <div class="d-flex justify-center align-center">
              <div class="edit--button" style="cursor: pointer" @click="editNote(item)">
                <v-icon size="32" v-text="'mdi-pencil'" v-if="permissions.includes('EDIT')" />
              </div>
              <base-delete-button
                :permissions="permissions"
                :id="item.id ? item.id.toString() : null"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>
            {{
              item.text && item.text.length > 1000 ? item.text.substring(0, 997) + '...' : item.text
            }}
          </td>
          <td>{{ item.tag ? item.tag.name : '' }}</td>
          <td>{{ item.created_by }}</td>
          <!--          <td>{{ item.updated_by }}</td>-->
          <td>{{ format(new Date(item.created_at), 'dd MMMM yyyy HH:mm') }}</td>
          <!--          <td>{{ format(new Date(item.updated_at), 'dd MMMM yyyy HH:mm') }}</td>-->
        </tr>
      </tbody>
    </v-simple-table>
    <!--    <pagination-->
    <!--      :push-state="false"-->
    <!--      :meta="meta"-->
    <!--      @changePage="changePage"-->
    <!--      :maxVisibleButtons="5"-->
    <!--      v-if="meta"-->
    <!--    />-->
    <modal-note></modal-note>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
// const Pagination = () => import('@/components/utils/fractal-pagination');
const ModalNote = () => import('@/components/modals/modal-note');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    // Pagination,
    ModalNote,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.belirumaUser.basePath,
    }),
  },
  methods: {
    // changePage(page) {
    //   this.$emit('changePage', page);
    // },
    async addNote() {
      this.$store.commit('belirumaUser/form/SET_NOTE_FORM_TYPE', 'ADD');
      this.$modal.show('modal-note', {
        dispatchAfterString: 'belirumaUser/getDetailInitData',
      });
    },
    async editNote(note) {
      this.$store.commit('belirumaUser/form/SET_NOTE_FORM_TYPE', 'EDIT');
      this.$modal.show('modal-note', {
        note: note,
        dispatchAfterString: 'belirumaUser/getDetailInitData',
      });
    },
    async deleteAction(id) {
      try {
        await this.$store.dispatch('belirumaUser/form/deleteNote', id);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('belirumaUser.deleteNote.successMsg'),
          'success',
        );
        try {
          this.$store.commit('belirumaUser/RESET_DEFAULT');
          this.$store.commit('belirumaUser/form/SET_FORM_TYPE', 'EDIT');
          await this.$store.dispatch('belirumaUser/getDetailInitData', this.$route.params.uuid);
        } catch (e) {
          this.showAlert = true;
          this.messageAlert = e;
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR DELETE NOTES: ', e);
      }
    },
  },
};
</script>
